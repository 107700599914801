import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import Card from '@shoreag/base/Card';
import Dropdown from '@shoreag/base/Dropdown';
import Input from '@shoreag/base/Input';
import Modal from '@shoreag/base/Modal';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import ReactGA from 'react-ga';
import Spinner from '@shoreag/base/Spinner';
import TextArea from '@shoreag/base/TextArea';
import arrayMutators from 'final-form-arrays';
import get from 'lodash/get';
import stripTypename from '@shoreag/helpers/strip-typename';
import { AuthContext } from '@shoreag/auth';
import { FORM_ERROR } from 'final-form';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { Query, Mutation, useQuery } from 'react-apollo';
import { TooltipBox } from '@shoreag/base/Tooltip';
import { parse } from 'query-string';
import { required } from '@shoreag/validations';
import ColumnDefinitionsForm from '../ColumnDefinitionsForm';
import FormError from '../FormError';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import SubmitButton from '../SubmitButton';
import SubtleText from '../SubtleText';
import TagsForm from '../TagsForm';
import DatumCreateForm from '../CreateDatumForm';
import createSchemaMutation from '../../graphql/mutations/create-schema.gql';
import findDuplicateStringsInArray from '../../utilities/find-duplicate-strings-in-array';
import isPermitted from '../../utilities/is-permitted';
import parseUuid from '../../utilities/parse-uuid';
import useSnackbar from '../../utilities/use-snackbar';
import partnerQuery from '../../graphql/queries/all-partner.gql';
import schemaCreatePageQuery from '../../graphql/queries/schema-create-page.gql';
import schemaQuery from '../../graphql/queries/schema.gql';
import {
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '../../utilities/constants';
import tradingPartners from '../../utilities/get-trading-partners';
import ButtonGroups from '../ButtonGroups';

const SchemaCreatePage = ({ location, navigate, schemaIdWithVersion }) => {
  const { user } = useContext(AuthContext);
  const theme = useContext(ThemeContext);
  const [showCreateDatumModal, setShowCreateDatumModal] = useState(false);

  const [setSnack] = useSnackbar({
    style: { backgroundColor: theme.colors.success },
  });

  const hasCreateDatumPermission = isPermitted({
    desiredAction: PERMISSION_ACTIONS.W,
    desiredResource: PERMISSION_RESOURCES.DATUMS,
    ...user,
  });

  if (
    !isPermitted({
      desiredAction: PERMISSION_ACTIONS.W,
      desiredResource: PERMISSION_RESOURCES.SCHEMAS,
      ...user,
    })
  ) {
    return <NotFoundPage />;
  }

  const { id, version } = parseUuid(schemaIdWithVersion);
  const queryParams = parse(location.search);
  const datasetId = get(queryParams, 'datasetId', null);
  let fileHeaderData = {};

  const { data, loading: pageDataLoading } = useQuery(schemaCreatePageQuery, {
    skip: !datasetId,
    variables: { id: datasetId, isSuggestionsEnabled: true },
  });

  const { data: partnerData } = useQuery(partnerQuery, { skip: datasetId });

  const partnerList = tradingPartners(
    datasetId
      ? get(data, 'allPartner', [])
      : get(partnerData, 'allPartner', []),
    user
  );

  const fileData = get(data, 'fileMetadata.result', null);

  if (fileData) {
    const parseFileData = JSON.parse(fileData);

    fileHeaderData =
      parseFileData && parseFileData.headers ? parseFileData : [];
  }

  return (
    <Route
      header={{
        icon: 'lists',
        rightContainer: <SubtleText>* indicates required</SubtleText>,
        title: `${id ? 'Update' : 'Create'} Schema`,
        type: 'Schema',
      }}
      isPrivate
    >
      <Query
        query={schemaQuery}
        skip={!schemaIdWithVersion}
        variables={{ id: schemaIdWithVersion }}
      >
        {({ loading, data }) => {
          if (pageDataLoading || loading) return <Spinner />;
          const schema = get(data, 'datasetSchema');
          const schemaVersions = get(data, 'datasetSchema.versions', []);

          const schemaVersion =
            schemaVersions.find((v) => v.versionNumber === String(version)) ||
            schemaVersions[0];

          const getDatumId = (definition) => {
            if (definition.nestedSchemaWithVersion) {
              return definition.nestedSchemaWithVersion.id;
            }

            return definition.id ? definition.id.split('::').shift() : null;
          };

          let initialValues = {
            datumIds:
              schema && schemaVersion.definitions.length > 0
                ? schemaVersion.definitions.map((definition) => ({
                    datumId: getDatumId(definition),
                    fieldName: definition.fieldName,
                    isMandatory: definition.isMandatory,
                    isNestedSchema: definition.nestedSchemaWithVersion
                      ? 'schema'
                      : 'datum',
                    multiple: definition.multiple,
                    primary: definition.primary,
                  }))
                : [
                    {
                      datumId: undefined,
                      fieldName: undefined,
                      isMandatory: undefined,
                      isNestedSchema: 'datum',
                      multiple: undefined,
                      primary: undefined,
                    },
                  ],
            description: get(schema, 'description'),
            name: get(schema, 'name'),
            partner:
              schema && schemaVersion.partner
                ? schemaVersion.partner.id
                : undefined,
            tags:
              schema && schemaVersion.tags
                ? stripTypename(schemaVersion.tags)
                : null,
          };

          if (Object.keys(fileHeaderData).length) {
            const datumList = [];
            fileHeaderData.headers.forEach((header) => {
              const datumHeader = header ? header.split('.').pop() : header;
              const suggestedDatumIds = get(
                fileHeaderData,
                datumHeader,
                []
              ).map((h) => h.datumDefinitionId);
              datumList.push({
                datumId: suggestedDatumIds[0],
                fieldName: header,
                isMandatory: false,
                isNestedSchema: 'datum',
                multiple: false,
                primary: false,
              });
            });

            initialValues = {
              ...initialValues,
              datumIds: datumList,
            };
          }

          const filledUpSchemaValues = get(location.state, 'schemaValues');
          initialValues = filledUpSchemaValues || initialValues;

          return (
            <Mutation mutation={createSchemaMutation}>
              {(createSchema) => (
                <FinalForm
                  initialValues={initialValues}
                  mutators={arrayMutators}
                  onSubmit={async (values) => {
                    const updatedValues = {
                      ...values,
                      datasetId,
                      datumIds: values.datumIds.map((datumId) => {
                        const datumObj = {
                          ...datumId,
                          fieldName: datumId.fieldName.trim(),
                        };

                        delete datumObj.isNestedSchema;

                        if ('isChecked' in datumObj) {
                          delete datumObj.isChecked;
                        }

                        if (datumId.isNestedSchema === 'schema') {
                          delete datumObj.datumId;

                          return {
                            ...datumObj,
                            nestedSchemaId: datumId.datumId,
                          };
                        }

                        return datumObj;
                      }),
                    };

                    try {
                      const {
                        data: {
                          createSchema: { id: newId },
                        },
                      } = await createSchema({
                        variables: id
                          ? { ...updatedValues, schemaId: id }
                          : updatedValues,
                      });

                      ReactGA.event({
                        action: `Schema ${id ? 'Edited' : 'Created'}`,
                        category: 'Schemas',
                        label: newId,
                      });

                      navigate(datasetId ? `/datasets/` : `/schemas/${newId}`);
                    } catch (e) {
                      return {
                        [FORM_ERROR]: get(e, 'graphQLErrors[0].message'),
                      };
                    }
                  }}
                  render={({
                    form: {
                      mutators: { push },
                    },
                    ...formContext
                  }) => (
                    <form onSubmit={formContext.handleSubmit}>
                      <FormSpy subscription={{ values: true }}>
                        {({ form, values }) => {
                          const isChecked = values.datumIds.some(
                            (item) => item.isChecked === true
                          );

                          const handleDuplicate = () => {
                            const itemsToDuplicate = values.datumIds.filter(
                              (datum) => datum.isChecked
                            );
                            if (itemsToDuplicate.length === 0) {
                              return;
                            }
                            const duplicatedItems = itemsToDuplicate.map(
                              (item) => ({ ...item, isChecked: false })
                            );
                            const updatedDatumIds = [
                              ...values.datumIds,
                              ...duplicatedItems,
                            ];
                            form.change('datumIds', updatedDatumIds);
                          };
                          const handleDelete = () => {
                            const updatedDatumIds = values.datumIds.filter(
                              (item) => !item.isChecked
                            );
                            const updatedItems = updatedDatumIds.map((item) => {
                              const { isChecked, ...rest } = item;
                              return rest;
                            });
                            form.change('datumIds', updatedItems);
                          };

                          return (
                            <>
                              <Card
                                mx="auto"
                                sx={{ maxWidth: 'maxWidths.form' }}
                              >
                                <Field
                                  component={Input}
                                  disabled={!!id}
                                  label="Name *"
                                  name="name"
                                  validate={required}
                                />
                                <Field
                                  component={TextArea}
                                  disabled={!!id}
                                  label="Description *"
                                  name="description"
                                  validate={required}
                                />
                                <Field
                                  component={Dropdown}
                                  data-cy="partner"
                                  label="Partner Name"
                                  name="partner"
                                  options={[
                                    { id: '', partnerName: 'Select' },
                                    ...partnerList,
                                  ].map((partner) => ({
                                    label: partner.partnerName,
                                    value: partner.id,
                                  }))}
                                />
                                <TagsForm push={push} />
                              </Card>
                              <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="flex-end"
                                sx={{ mb: 4, mt: 6 }}
                              >
                                {hasCreateDatumPermission && (
                                  <p>
                                    If you are not able to find datum definition
                                    in dropdown then you can create datum from
                                    here:
                                    <Button
                                      onClick={() =>
                                        setShowCreateDatumModal(true)
                                      }
                                      sx={{
                                        bg: 'primary',
                                        color: 'white',
                                        display: 'inline-block',
                                        height: 'auto',
                                        ml: 4,
                                        px: 6,
                                        py: 2,
                                      }}
                                      variant="buttons.transparent"
                                    >
                                      + Add Datum
                                    </Button>
                                  </p>
                                )}
                              </Box>
                              <Box
                                bg="white"
                                sx={{
                                  alignItems: 'center',
                                  borderBottom: '1px solid',
                                  borderColor: 'grays.2',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  px: 6,
                                  py: 4,
                                }}
                              >
                                <Box as="h2" fontSize={4}>
                                  Datum definitions
                                </Box>
                                <ButtonGroups>
                                  <Button
                                    bg="accentSecondary"
                                    disabled={!isChecked}
                                    onClick={handleDuplicate}
                                  >
                                    Duplicate
                                  </Button>
                                  <Button
                                    bg="transparent"
                                    color="accentSecondary"
                                    disabled={!isChecked}
                                    onClick={handleDelete}
                                    sx={{
                                      '&:hover': {
                                        color: 'white',
                                      },
                                      border: '1px solid',
                                      borderColor: 'accentSecondary',
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </ButtonGroups>
                              </Box>
                              <ColumnDefinitionsForm
                                enableBuildSchema={!!datasetId}
                                fileHeaderData={fileHeaderData}
                                push={push}
                                title="Add Column Definition"
                              />
                              <TooltipBox
                                id="tooltip"
                                tooltipProps={{
                                  delayHide: 200,
                                  place: 'right',
                                }}
                              />
                              <FormError>{formContext.submitError}</FormError>
                              <SubmitButton
                                disabled={formContext.pristine}
                                mt="lg"
                                submitting={formContext.submitting}
                              >
                                Save Schema
                              </SubmitButton>
                            </>
                          );
                        }}
                      </FormSpy>
                    </form>
                  )}
                  subscription={{
                    handleSubmit: true,
                    pristine: true,
                    submitError: true,
                    submitting: true,
                  }}
                  validate={(values) => {
                    const errors = {};
                    const duplicateFieldNames = findDuplicateStringsInArray(
                      values.datumIds.map((datum) => datum.fieldName)
                    );

                    if (duplicateFieldNames.length && values.datumIds) {
                      const datumIdsErrors = values.datumIds.map((datum) =>
                        duplicateFieldNames.includes(datum.fieldName)
                          ? { fieldName: 'Must be unique' }
                          : null
                      );
                      errors.datumIds = datumIdsErrors;
                    }
                    return errors;
                  }}
                />
              )}
            </Mutation>
          );
        }}
      </Query>
      <Modal
        bodySx={{
          backgroundColor: 'white',
          pb: 0,
          pt: 0,
          px: 0,
        }}
        isOpen={showCreateDatumModal}
        onClose={() => {
          setShowCreateDatumModal(false);
        }}
        style={{
          content: {
            maxWidth: theme.sizes.maxWidths.form,
          },
        }}
        title="Create datum"
      >
        <DatumCreateForm
          onSuccess={({ name }) => {
            setSnack(
              <>
                Datum <b>{name}</b> has been added successfully.
              </>
            );
            setShowCreateDatumModal(false);
          }}
        />
      </Modal>
    </Route>
  );
};

SchemaCreatePage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    state: PropTypes.shape({}),
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  schemaIdWithVersion: PropTypes.string,
};

SchemaCreatePage.defaultProps = {
  schemaIdWithVersion: null,
};

export default SchemaCreatePage;
